<template>
  <div class="text-center">
    <v-dialog
      v-model="isDialogOpen"
      :width="width"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          outlined
          color="primary"
          :style="{width: $vuetify.breakpoint.xsOnly && '100%' }"
          v-bind="attrs"
          v-on="on"
        >
          PDF erstellen
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>PDF erstellen</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="cancel"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <v-alert
            icon="mdi-information-outline"
            text
            type="info"
            class="mt-4"
          >
            Sie können eine PDF für die Bewertung erstellen. Dazu müssen Sie die Bewertung einem Kontakt zuweisen.
            <br><b>Wichtig: </b> Nachdem eine Bewertung zugewiesen und das PDF erstellt wurde, kann dies nicht mehr rückgängig gemacht werden.
          </v-alert>
          <LeadSearch
            v-if="isDialogOpen"
            @select="handleSelection"
            @created="handleCreated"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            text
            @click="cancel"
          >
            Abbrechen
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            :disabled="!canAssignValuationToLead"
            @click.prevent="assignValuationToLead"
          >
            PDF erstellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LeadSearch from '@/components/LeadSearch.vue'
import ASSIGN_VALUATION_TO_LEAD from './queries/assignValuationToLead.gql'
import ENQUEUE_PDF_GENERATION from './queries/enqueuePdfGeneration.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: { LeadSearch },
  props: {
    valuationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isDialogOpen: false,
      selectedLeadId: ''
    }
  },
  computed: {
    canAssignValuationToLead () {
      return this.selectedLeadId !== ''
    },
    width () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 350
        case 'sm': return 350
        case 'md': return 600
        case 'lg': return 600
        case 'xl': return 500
      }
      return 300
    }
  },
  methods: {
    cancel () {
      this.isDialogOpen = false
    },
    handleSelection (data) {
      this.selectedLeadId = data
    },
    handleCreated (leadId) {
      this.selectedLeadId = leadId
      this.assignValuationToLead()
    },
    async assignValuationToLead () {
      try {
        // assign valuation to lead
        const { data: { valuation } } = await this.$apollo.mutate({
          mutation: ASSIGN_VALUATION_TO_LEAD,
          variables: {
            input: {
              valuationId: this.valuationId,
              leadId: this.selectedLeadId
            }
          }
        })
        // send mutation to generate PDF once assigned
        await this.$apollo.mutate({
          mutation: ENQUEUE_PDF_GENERATION,
          variables: {
            valuationId: this.valuationId
          }
        })

        this.$emit('assigned', valuation.leadId)
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Erfolgreich zugewiesen', newTime: 10000 })
        this.isDialogOpen = false
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Zuweisung fehlgeschlagen', newTime: 10000 })
      }
    }
  }
}
</script>
