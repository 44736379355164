<template>
  <div
    v-if="results"
    class="h-full"
  >
    <VueApexCharts
      height="100%"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import dateMixin from '@/mixins/date'
import spaceUnitMixin from '@/mixins/spaceUnit'
import brandingMixin from '@/mixins/branding'

import VueApexCharts from 'vue-apexcharts'
import { shadeColor } from '@/lib/color'

const de = {
  name: 'de',
  options: {
    shortMonths: [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez'
    ]
  }
}

export default {
  components: { VueApexCharts },

  mixins: [currencyMixin, dateMixin, spaceUnitMixin, brandingMixin],

  props: {
    results: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      chartOptions: {
        chart: {
          locales: [de],
          defaultLocale: 'de',
          type: 'area',
          parentHeightOffset: 0,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 0
        },
        fill: {
          opacity: 1,
          type: 'solid'
        },
        legend: {
          show: true,
          position: 'top'
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: false
          }
        }
      }
    }
  },

  computed: {
    series () {
      if (this.results) {
        const maxSeries = []
        const avgSeries = []
        const minSeries = []

        this.results.property.valueTrend.forEach(item => {
          maxSeries.push(item.valueRange.max)
          avgSeries.push(item.value)
          minSeries.push(item.valueRange.min)
        })
        this.updateLabels(this.results.property)
        return [
          {
            name: 'Maximum',
            data: maxSeries.reverse()
          },
          {
            name: 'Durchschnitt',
            data: avgSeries.reverse()
          },
          {
            name: 'Minimum',
            data: minSeries.reverse()
          }
        ]
      }
      return []
    },
    customColors () {
      return [shadeColor(this.primaryColor, -80), shadeColor(this.primaryColor, 40), this.primaryColor]
    }
  },

  methods: {
    updateLabels (property) {
      const context = this
      const dates = property.valueTrend.map(({ date }) => date)
      this.chartOptions = {
        ...this.chartOptions,
        labels: dates.reverse(),
        yaxis: {
          labels: {
            formatter (val) {
              return context.formatPrice(val)
            }
          }
        },
        colors: this.customColors
      }
    }
  }
}
</script>
