<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 95.1 94.3"
    enable-background="new 0 0 95.1 94.3"
    xml:space="preserve"
  >

    <gradient-helper />

    <path
      fill="url(#svg-gradient)"
      d="M95.1,94.3V42.4H64c-5.9,0-10.6-4.8-10.6-10.6V0H0.2L0,35.6c8.3,0.8,15.4,4.1,21.5,10.2c6.9,6.9,10.3,15.3,10.3,25.1v23.4C31.8,94.3,95.1,94.2,95.1,94.3"
    />
    <path
      fill="url(#svg-gradient)"
      d="M7.6,94.3V70.9c0-2.5-0.7-4.7-2-6.6c-1.3-1.9-3.1-3.2-5.2-4v34L7.6,94.3z"
    />
    <path
      fill="url(#svg-gradient)"
      d="M77.4,21.3h14.2c2,0,3.5-1.6,3.5-3.5V3.5c0-2-1.6-3.5-3.5-3.5H77.4c-2,0-3.5,1.6-3.5,3.5v14.2C73.9,19.7,75.5,21.3,77.4,21.3"
    />
  </svg>
</template>

<script>
import svg from '@/mixins/svg'
import GradientHelper from './GradientHelper.vue'
export default {
  components: { GradientHelper },
  mixins: [svg]
}
</script>
