<template>
  <div>
    <UpperB
      class="banner-design-elements"
      width="200px"
      style="right: -50px; top:-10px; opacity: 0.4"
    />

    <Leaf
      class="banner-design-elements"
      width="100px"
      style="right: 100px; top:50px; opacity: 0.8"
      stroke
      :stroke-width="1"
    />

    <Leaf
      class="banner-design-elements"
      width="150px"
      style="right: 200px; top:-50px; opacity: 0.3"
      stroke
      :stroke-width="1"
    />
  </div>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'
import UpperB from '@/components/design-elements/upperB.vue'

export default {
  components: {
    Leaf,
    UpperB
  }
}
</script>

<style>

</style>
