<template>
  <v-card outlined>
    <div
      ref="map"
      class="map"
    />
  </v-card>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import mapMixin from '@/mixins/map'

export default {
  mixins: [currencyMixin, mapMixin],
  props: {
    valuation: {
      type: Object,
      required: true
    }
  },
  async mounted () {
    this.loadGoogleMaps()
    await this.googleMapsLoaded()

    const avg = this.valuation.results.property.value
    const currency = this.valuation.results.currency
    const address = {
      ...this.valuation.inputs.address,
      ...this.valuation.inputs.coordinates
    }

    const lat = address.lat
    const lng = address.lng

    // eslint-disable-next-line no-undef
    this.map = new google.maps.Map(this.$refs.map, {
      center: { lat, lng },
      zoom: 14,
      fullscreenControl: false,
      streetViewControl: true,
      mapTypeControl: true
    })

    // eslint-disable-next-line no-undef
    const infoWindow = new google.maps.InfoWindow()

    this.createMarker(
      infoWindow,
      address,
      this.formatPrice(avg, currency)
    )

    this.enableStreetView(lat, lng)
  },
  methods: {
    async enableStreetView (lat, lng) {
      const { available, streetViewPanoramaData } = await this.getStreetViewData({ lat, lng })
      if (available) {
        this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
          this.createButtonForMap(
            'Street View',
            'map-button',
            () => this.createStreetViewPanorama({
              streetViewPanoramaData,
              lat,
              lng,
              map: this.$refs.map
            })
          )
        )
      }
    },
    createMarker (infoWindow, address, price) {
      // eslint-disable-next-line no-undef
      const marker = new google.maps.Marker({
        position: { lat: address.lat, lng: address.lng },
        map: this.map,
        icon: {
          url: '/img/marker/active.svg',
          scaledSize: { height: 50, width: 50 }
        },
        zIndex: 1,

        // eslint-disable-next-line no-undef
        animation: google.maps.Animation.DROP
      })

      marker.addListener('click', function () {
        infoWindow.setContent(
          '<div style="width:200px">' +
            `<h2>${price}</h2>` +
            '<br>' +
            '<div>' +
            `${address.street} ${address.houseNumber}` +
            '</div>' +
            '<div>' +
            `${address.zip} ${address.city}` +
            '</div>' +
            '</div>'
        )

        infoWindow.open(this.map, marker)
      })

      return marker
    }
  }
}
</script>

<style scoped>
.map {
  height: 400px;
}

.legend {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
</style>

<style lang="postcss">
.map-button, .street-view-button {
  margin: 10px 0 22px 10px;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0,0,0,.2);
  cursor: pointer;
  margin: 10px 0 22px 0;
  text-align: center;
  color: rgb(25,25,25);
  font-size: 16px;
  line-height: 36px;
  padding: 0 5px;
}
.street-view-button {
  margin: 10px 0 22px 10px;
}
</style>
