<template>
  <BaseHeader
    title="Immobilienbewertung"
    :style-variant="5"
  >
    <template v-slot:design>
      <HeaderDesign />
    </template>

    <template v-slot:desc>
      <p>
        Nutzen Sie die Immobilienbewertung, um sich auf Kundengespräche vorzubereiten und machen Sie auf Ihre Expertise aufmerksam.
      </p>
      <p v-if="isExpert">
        Jetzt neu für noch mehr Effizienz in Ihrem Alltag: Weisen Sie die erstellten Bewertungen direkt Ihren Kontakten zu. <br>
        Die Bewertungen erscheinen dann auch in der Detailansicht Ihres Kontakts. So haben Sie stets den Überblick.<br>
      </p>
      <p><b>Jetzt direkt loslegen und Adresse eingeben.</b></p>
    </template>
    <template v-slot:content>
      <v-slide-y-transition>
        <v-form
          ref="form"
          @submit.prevent="handleSubmit"
        >
          <v-card-text>
            <v-row
              class="justify-center mt-1 d-flex"
              :class="{'mb-n6':!inputs.type, 'justify-space-between mb-3 mb-md-0':inputs.type}"
            >
              <v-col
                cols="12"
                md="6"
                class="py-1 py-md-2"
              >
                <AddressField
                  v-model="inputs.place"
                  :country-code="countryCode"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col
                v-if="inputs.place"
                cols="12"
                sm="6"
                md="3"
                class="py-1 py-md-2"
              >
                <v-select
                  v-model="inputs.type"
                  label="Art der Immobilie"
                  :items="typeList"
                  :rules="[rules.required]"
                  outlined
                  dense
                  required
                />
              </v-col>

              <v-col
                v-if="inputs.type"
                cols="12"
                sm="6"
                md="3"
                class="py-1 py-md-2"
              >
                <v-select
                  v-if="subTypeList"
                  v-model="inputs.subType"
                  :label="subTypeLabel"
                  :items="subTypeList"
                  :rules="[rules.required]"
                  outlined
                  dense
                  required
                />
              </v-col>
            </v-row>

            <v-expand-transition>
              <div v-if="expanded">
                <v-row class="mb-n3 mb-md-n5">
                  <v-col
                    cols="12"
                    md="3"
                    class="py-1 py-md-2"
                  >
                    <v-text-field
                      v-model.number="inputs.buildingYear"
                      type="number"
                      label="Baujahr"
                      :hint="getRangeHint('buildingYear')"
                      :min="getRangeMin('buildingYear')"
                      :max="getRangeMax('buildingYear')"
                      :rules="rules.buildingYear"
                      validate-on-blur
                      outlined
                      dense
                      required
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                    class="py-1 py-md-2"
                  >
                    <v-text-field
                      v-if="inputs.type === types.MULTI_FAMILY_HOUSE"
                      v-model.number="inputs.numberOfUnits"
                      type="number"
                      label="Anzahl Einheiten"
                      step="1"
                      :hint="getRangeHint('numberOfUnits')"
                      :min="getRangeMin('numberOfUnits')"
                      :max="getRangeMax('numberOfUnits')"
                      :rules="rules.numberOfUnits"
                      validate-on-blur
                      outlined
                      dense
                      required
                    />

                    <v-text-field
                      v-else
                      v-model.number="inputs.numberOfRooms"
                      type="number"
                      label="Anzahl Zimmer"
                      step="0.5"
                      :hint="getRangeHint('numberOfRooms')"
                      :min="getRangeMin('numberOfRooms')"
                      :max="getRangeMax('numberOfRooms')"
                      :rules="rules.numberOfRooms"
                      validate-on-blur
                      outlined
                      dense
                      required
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                    class="py-1 py-md-2"
                  >
                    <v-text-field
                      v-model.number="inputs.livingArea"
                      type="number"
                      suffix="m²"
                      label="Wohnfläche"
                      :hint="getRangeHint('livingArea')"
                      :min="getRangeMin('livingArea')"
                      :max="getRangeMax('livingArea')"
                      :rules="rules.livingArea"
                      validate-on-blur
                      outlined
                      dense
                      required
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                    class="py-1 py-md-2"
                  >
                    <v-text-field
                      v-if="inputs.type === types.APARTMENT"
                      v-model.number="inputs.balconyArea"
                      type="number"
                      label="Garten-/Balkonfläche"
                      suffix="m²"
                      :hint="getRangeHint('balconyArea')"
                      :min="getRangeMin('balconyArea')"
                      :max="getRangeMax('balconyArea')"
                      :rules="rules.balconyArea"
                      validate-on-blur
                      outlined
                      dense
                      required
                    />

                    <v-text-field
                      v-else
                      v-model.number="inputs.landArea"
                      type="number"
                      label="Grundstücksfläche"
                      suffix="m²"
                      :hint="getRangeHint('landArea')"
                      :min="getRangeMin('landArea')"
                      :max="getRangeMax('landArea')"
                      :rules="rules.landArea"
                      validate-on-blur
                      outlined
                      dense
                      required
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    class="py-1 py-md-2"
                  >
                    <v-select
                      v-model="inputs.condition"
                      label="Zustand"
                      :items="conditionList"
                      :rules="[rules.required]"
                      outlined
                      dense
                      required
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                    class="py-1 py-md-2"
                  >
                    <v-select
                      v-model="inputs.quality"
                      label="Qualität"
                      :items="qualityList"
                      :rules="[rules.required]"
                      outlined
                      dense
                      required
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                    class="py-1 py-md-2"
                  >
                    <v-text-field
                      v-if="inputs.type === types.MULTI_FAMILY_HOUSE"
                      v-model.number="inputs.annualRentIncome"
                      type="number"
                      label="Mieteinnahmen/Jahr"
                      :hint="getRangeHint('annualRentIncome')"
                      :min="getRangeMin('annualRentIncome')"
                      :max="getRangeMax('annualRentIncome')"
                      :rules="rules.annualRentIncome"
                      validate-on-blur
                      outlined
                      dense
                      required
                    />

                    <v-text-field
                      v-if="inputs.type === types.APARTMENT && inputs.subType !== 'APARTMENT_TERRACED'"
                      v-model.number="inputs.floorNumber"
                      type="number"
                      label="Stockwerk"
                      :hint="getRangeHint('floorNumber')"
                      :min="getRangeMin('floorNumber')"
                      :max="getRangeMax('floorNumber')"
                      :rules="rules.floorNumber"
                      validate-on-blur
                      outlined
                      dense
                      required
                    />
                  </v-col>
                </v-row>

                <v-expand-transition>
                  <v-row v-if="isState('ERROR')">
                    <v-col>
                      <v-alert
                        type="error"
                        dense
                        tile
                        text
                      >
                        {{ errorMessage }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-expand-transition>

                <!-- reverse (if !mobile) so that the submit button is first focused on tab -->
                <v-card-actions class="flex-column flex-md-row-reverse">
                  <LoadingButton
                    color="primary"
                    type="submit"
                    class="my-8 my-md-0"
                    :loading="isState('SUBMITTING')"
                  >
                    Bewertung starten
                  </LoadingButton>
                  <v-spacer />
                  <v-btn
                    text
                    @click="resetForm"
                  >
                    Eingaben löschen
                  </v-btn>
                </v-card-actions>
              </div>
            </v-expand-transition>
          </v-card-text>
        </v-form>
      </v-slide-y-transition>
    </template>
  </BaseHeader>
</template>

<script>
import * as rules from '@/lib/validation'
import LoadingButton from '@/components/loading-button'
import GET_INPUT_LIMITS from './queries/getInputLimits.gql'
import CREATE_VALUATION from './queries/createValuation.gql'
import AddressField from './AddressField'
import brandingMixin from '@/mixins/branding'
import { isExpertUser } from '@/lib/productTypes'
import featureNames from '@/lib/featureNames'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from './HeaderDesign.vue'

const states = {
  IDLE: 'IDLE',
  SUBMITTING: 'SUBMITTING',
  ERROR: 'ERROR'
}

const types = {
  APARTMENT: 'APARTMENT',
  HOUSE: 'HOUSE',
  MULTI_FAMILY_HOUSE: 'MULTI_FAMILY_HOUSE'
}

const typeListAll = [
  { value: types.APARTMENT, text: 'Wohnung' },
  { value: types.HOUSE, text: 'Einfamilienhaus' },
  { value: types.MULTI_FAMILY_HOUSE, text: 'Mehrfamilienhaus' }
]

const subTypeLists = countryCode => ({
  [types.APARTMENT]: [
    { value: 'APARTMENT_TERRACED', text: 'Erdgeschoss' },
    { value: 'APARTMENT_NORMAL', text: 'Etagenwohnung' },
    { value: 'APARTMENT_STUDIO', text: 'Studio' },
    { value: 'APARTMENT_MAISONETTE', text: 'Maisonette' },
    { value: 'APARTMENT_PENTHOUSE', text: countryCode === 'ch' ? 'Attika' : 'Penthouse' },
    { value: 'APARTMENT_ATTIC', text: 'Dachgeschoss' }
  ],
  [types.HOUSE]: [
    { value: 'HOUSE_DETACHED', text: 'Freistehend' },
    { value: 'HOUSE_SEMI_DETACHED', text: 'Doppelhaushälfte' },
    { value: 'HOUSE_ROW_CORNER', text: 'Reihenendhaus' },
    { value: 'HOUSE_ROW_MIDDLE', text: 'Reihenmittelhaus' },
    { value: 'HOUSE_FARM', text: 'Landhaus' }
  ]
})

const qualityList = [
  { value: 'SIMPLE', text: 'Einfach' },
  { value: 'NORMAL', text: 'Standard' },
  { value: 'HIGH', text: 'Hoch' },
  { value: 'LUXURY', text: 'Luxuriös' }
]

const conditionList = [
  { value: 'RENOVATION_NEEDED', text: 'Renovierungsbedürftig' },
  { value: 'WELL_MAINTAINED', text: 'Gut erhalten' },
  { value: 'NEW_OR_RECENTLY_RENOVATED', text: 'Neu oder kürzlich renoviert' }
]

const subTypeLabels = {
  [types.APARTMENT]: 'Art der Wohnung',
  [types.HOUSE]: 'Art des Hauses'
}

const getInitialInputs = () => ({
  place: null,
  type: null,
  subType: null,
  buildingYear: '',
  numberOfRooms: '',
  numberOfUnits: '',
  livingArea: '',
  landArea: '',
  balconyArea: '',
  condition: '',
  quality: '',
  floorNumber: '',
  annualRentIncome: ''
})

export default {
  components: {
    LoadingButton,
    AddressField,
    BaseHeader,
    HeaderDesign
  },

  mixins: [brandingMixin],

  data: () => ({
    state: states.IDLE,
    errorMessage: '',
    inputs: getInitialInputs(),
    types,
    conditionList,
    qualityList,
    image: '/img/banner-valuation.png'
  }),

  computed: {
    expanded () {
      return this.inputs.type && this.inputLimits
    },

    typeList () {
      return typeListAll.filter(({ value }) => {
        const type = this.$features.feature(featureNames.VALUATION).config.enabledTypes[value]
        return type && type !== 'MANUAL'
      })
    },

    subTypeList () {
      return subTypeLists(this.countryCode)[this.inputs.type]
    },

    countryCode () {
      return (
        {
          'de-AT': 'at',
          'de-CH': 'ch'
        }[this.$auth.user.locale] || 'de'
      )
    },

    subTypeLabel () {
      return subTypeLabels[this.inputs.type]
    },

    rules () {
      return {
        required: rules.required,
        buildingYear: [rules.required, rules.integer, this.getRangeRule('buildingYear')],
        numberOfUnits: [rules.required, rules.integer, this.getRangeRule('numberOfUnits')],
        numberOfRooms: [rules.required, rules.halfNumber, this.getRangeRule('numberOfRooms')],
        livingArea: [rules.required, rules.integer, this.getRangeRule('livingArea')],
        balconyArea: [rules.required, rules.integer, this.getRangeRule('balconyArea')],
        landArea: [rules.required, rules.integer, this.getRangeRule('landArea')],
        floorNumber: [rules.required, rules.integer, this.getRangeRule('floorNumber')],
        annualRentIncome: [rules.required, rules.integer, this.getRangeRule('annualRentIncome')]
      }
    },
    isExpert () {
      return isExpertUser(this.$auth.user)
    }
  },

  apollo: {
    inputLimits: {
      query: GET_INPUT_LIMITS,
      variables () {
        return {
          input: {
            countryCode: this.countryCode,
            propertyType: this.inputs.type
          }
        }
      },
      skip () {
        return !this.inputs.type
      }
    }
  },

  watch: {
    'inputs.type' () {
      this.resetFormPartially()
    },

    'inputs.place' () {
      if (!this.inputs.place) {
        this.resetForm()
      }
    },

    state (state, previousState) {
      if (previousState === states.ERROR) {
        this.errorMessage = ''
      }
    }
  },

  methods: {

    isState (state) {
      return this.state === state
    },

    setState (state) {
      this.state = state
    },

    getRangeHint (property) {
      const limit = this.getLimit(property)
      return limit ? `zwischen ${limit.min} – ${limit.max}` : ''
    },

    getRangeMin (property) {
      const limit = this.getLimit(property)
      return limit ? limit.min : ''
    },

    getRangeMax (property) {
      const limit = this.getLimit(property)
      return limit ? limit.max : ''
    },

    getLimit (property) {
      if (!this.inputLimits) {
        return null
      }

      return this.inputLimits[property] || null
    },

    getRangeRule (key) {
      if (!this.inputLimits || !this.inputLimits[key]) {
        return true
      }
      const { min, max } = this.inputLimits[key]

      return value => rules.betweenRule(value, min, max)
    },

    async handleSubmit () {
      const formIsValid = this.$refs.form.validate()
      if (!formIsValid) {
        return
      }

      this.setState(states.SUBMITTING)

      const { place, ...inputs } = this.inputs
      const inputData = {
        ...inputs,
        ...place,
        buildingYear: parseInt(inputs.buildingYear),
        numberOfRooms: parseFloat(inputs.numberOfRooms),
        numberOfUnits: parseInt(inputs.numberOfUnits),
        livingArea: parseInt(inputs.livingArea),
        landArea: parseInt(inputs.landArea),
        balconyArea: parseInt(inputs.balconyArea),
        floorNumber: parseInt(inputs.floorNumber) || 0, // In case of APARTMENT_TERRACED it's 0
        annualRentIncome: parseInt(inputs.annualRentIncome)
      }

      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_VALUATION,
          variables: {
            input: inputData
          }
        })
        this.$emit('created', data.valuation)
        this.setState(states.IDLE)
      } catch (err) {
        this.setState(states.ERROR)
        this.errorMessage =
          'Etwas ist schiefgelaufen. Bitte probieren Sie es in Kürze erneut und wenden Sie sich an Ihren Ansprechpartner oder den Support.'
      }
    },

    resetForm () {
      this.inputs = getInitialInputs()
      this.$refs.form.resetValidation()
      this.setState(states.IDLE)
      this.$emit('reset')
    },

    // Reset most fields on type change
    resetFormPartially () {
      this.inputs = {
        ...getInitialInputs(),
        place: this.inputs.place,
        type: this.inputs.type
      }
      this.$refs.form.resetValidation()
      this.setState(states.IDLE)
      this.$emit('reset')
    }
  }
}
</script>

<style scoped>
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg{
  background-color: lightgray !important;
  opacity: 0.6 !important;
}
</style>
